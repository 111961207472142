import {BrowserRouter as Router, Switch} from 'react-router-dom'
import MainLayout from './layouts/MainLayout/MainLayout'
import {Redirect} from 'react-router'
import './assets/scss/App.scss'
import {PublicRoute} from './router/PublicRoute/PublicRoute'
import LoginPage from './pages/LoginPage/LoginPage'
import SimpleLayout from './layouts/SimpleLayout/SimpleLayout'
import {PrivateRoute} from './router/PrivateRoute/PrivateRoute'
import LangHelper from './helpers/LangHelper/LangHelper'
import DashboardPage from './pages/DashboardPage/DashboardPage'
import CompanyPage from './pages/CompanyPage/CompanyPage'
import CompaniesPage from './pages/CompaniesPage/CompaniesPage'
import QuotationPage from './pages/QuotationPage/QuotationPage'
import PreviewLayout from './layouts/PreviewLayout/PreviewLayout'
import TaxesPage from './pages/TaxesPage/TaxesPage'
import AIPage from './pages/AIPage/AIPage'

const App = () => {
  LangHelper.setDefaultLanguage()
  return (
    <Router basename={'/'}>
      <Switch>
        <PublicRoute exact path={'/'} component={() => <Redirect to={'/login'}/>} layout={SimpleLayout}/>
        <PublicRoute path={'/login'} component={LoginPage} layout={SimpleLayout}/>
        <PrivateRoute path={'/dashboard'} component={DashboardPage} layout={MainLayout}/>
        <PrivateRoute path={'/ai'} component={AIPage} layout={MainLayout}/>
        <PrivateRoute path={'/company'} component={CompanyPage} layout={MainLayout}/>
        <PrivateRoute path={'/editCompany'} component={CompanyPage} layout={MainLayout}/>
        <PrivateRoute path={'/companies'} component={CompaniesPage} layout={MainLayout}/>
        <PrivateRoute path={'/taxes'} component={TaxesPage} layout={MainLayout}/>
        <PublicRoute path={'/quotation'} component={QuotationPage} layout={PreviewLayout}/>
      </Switch>
    </Router>
  )
}

export default App
