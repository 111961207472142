import ReactDOM from 'react-dom/client'
import App from './App'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import ToastProvider from './components/organisms/ToastProvider/ToastProvider'
import { LicenseInfo } from '@mui/x-license-pro'

// eslint-disable-next-line jest/require-hook
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO)

const root = ReactDOM.createRoot(document.getElementById('root'))

const error = console.error
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return
  error(...args)
}

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#2C296A',
      black: '#000000',
      white: '#ffffff'
    },
    secondary: {
      main: '#66bb6a',
      second: '#ab47bc',
      grey: '#B5B5B5',
      greyLight: '#EBEBEB',
      greyDark: '#767373'
    },
    toasts: {
      success: '#2e7d32',
      warning: '#ed6c02',
      error: '#d32f2f',
      info: '#0288d1'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    useNextVariants: true
  },
  config: {
    maxToasts: 5,
    seo: {
      author: 'Your site, kontakt@your-site.pl',
      url: 'https://appraiser.your-site.pl',
      name: 'Jan Szymański',
      title: 'Your site - Appraiser',
      description: 'Your site',
      keywords: 'aplikacje webowe',
      category: 'aplikacje webowe'
    }
  }
})

// eslint-disable-next-line jest/require-hook
root.render(
  <ToastProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <App/>
    </ThemeProvider>
  </ToastProvider>
)
