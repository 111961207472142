import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import './CompaniesPage.scss'
import {Grid, useTheme} from '@mui/material'
import favicon from '../../assets/configFiles/logo.png'
import {Helmet} from 'react-helmet'
import {useEffect, useState} from 'react'
import {getAllDataFromPath} from '../../firebase'
import Box from '@mui/material/Box'
import {useHistory} from 'react-router'
import Button from '@mui/material/Button'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import Typography from '@mui/material/Typography'

const CompaniesPage = () => {
  const history = useHistory()
  const [search, setSearch] = useState('')
  const [rows, setRows] = useState(null)
  const theme = useTheme()
  const seo = theme.config.seo
  const columns = [
    {
      field: 'action',
      headerName: 'Edycja',
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation()
          history.push(`/editCompany/${params.row.id}`)
        }
        return <Button onClick={onClick} variant="contained">Edycja</Button>
      }
    },
    {
      field: 'companyName',
      headerName: 'Firma',
      width: 300,
      editable: false,
    },
    {
      field: 'nip',
      headerName: 'NIP',
      width: 150,
      type: 'number',
      editable: false,
    },
    {
      field: 'fullName',
      headerName: 'Imię i nazwisko',
      description: 'Ta kolumna nie jest możliwa do sortowania.',
      sortable: false,
      width: 180,
      editable: false,
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: false,
    },
    {
      field: 'phone',
      headerName: 'Telefon',
      width: 120,
      editable: false,
    },
    {
      field: 'address',
      headerName: 'Adres',
      width: 300,
      editable: false,
    },
    {
      field: 'website',
      headerName: 'Strona internetowa',
      width: 250,
      editable: false,
    },
    {
      field: 'linkFacebook',
      headerName: 'Facebook',
      width: 300,
      editable: false,
    },
    {
      field: 'linkInstagram',
      headerName: 'Instagram',
      width: 300,
      editable: false,
    },
    {
      field: 'linkLinkedin',
      headerName: 'LinkedIn',
      width: 300,
      editable: false,
    },
    {
      field: 'bankAccount',
      headerName: 'Numer konta',
      width: 300,
      editable: false,
    },
    {
      field: 'googleDrive',
      headerName: 'Dysk Google',
      width: 300,
      editable: false,
    },
  ]
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    arrayNotExist: {
      fontSize: '16px',
      marginTop: '20px',
      color: '#2C296A',
      background: '#ededed',
      padding: '20px',
      width: 'auto',
      marginLeft: '20px',
      borderRadius: '50px'
    },
    title: {
      fontSize: '22px',
      fontWeight: '600',
      marginBottom: '20px',
      color: 'black !important'
    },
    description: {
      fontSize: '16px',
      fontWeight: '400',
      marginBottom: '50px',
      whiteSpace: 'pre-wrap',
      color: 'black !important'
    },
    text: {
      color: 'black !important'
    },
    img: {
      width: '100px',
      height: '100px',
      fitObject: 'cover',
      borderRadius: '50%'
    },
    footer: {
      marginTop: '-15px'
    }
  }
  const getData = () => {
    getAllDataFromPath('companies').then((res) => {
      if (res) {
        res.forEach((el) => {
          el.permanentClient = el?.data?.tags[0] === 'Trzy i więcej zleceń (stały klient)'
        })
        const sortedArray = [
          ...res.filter(({ permanentClient }) => permanentClient),
          ...res.filter(({ permanentClient }) => !permanentClient)
        ]
        const temp = []
        sortedArray.forEach(el => {
          const tempObj = el.data
          tempObj.id = el.id
          temp.push(tempObj)
        })
        setRows(temp)
      }
    })
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {seo.title}
        </title>
        <link
          rel="icon"
          href={favicon}
        />
      </Helmet>
      <div style={style.root}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 'calc(100% - 60px)' }}>
              <Box>
                <Button variant="contained" onClick={() => history.push('/company')}>Dodaj firmę</Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box sx={{ width: 'calc(100% - 60px)' }}>
              {
                rows?.length > 0 && (
                  <DataGridPro
                    rows={rows}
                    columns={columns}
                    experimentalFeatures={{ clipboardPaste: true }}
                    unstable_ignoreValueFormatterDuringExport
                    localeText={{
                      toolbarExportPrint: 'Drukuj',
                      toolbarExportCSV: 'Wyeksportuj CSV',
                      toolbarFiltersTooltipHide: 'Ukryj',
                      toolbarFilters: 'Filtruj',
                      toolbarExport: 'Wyeksportuj',
                      toolbarColumns: 'Kolumny',
                      MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                          `${from} - ${to} większe niż ${count}`,
                      },
                      toolbarDensity: 'Wielkość',
                      toolbarDensityLabel: 'Wielkość',
                      toolbarDensityCompact: 'Mały',
                      toolbarDensityStandard: 'Średni',
                      toolbarDensityComfortable: 'Wielki',
                    }}
                    slots={{
                      toolbar: GridToolbar,
                      noRowsOverlay: (
                        <Typography>
                          Brak danych w tabelce
                        </Typography>
                      )
                    }}
                    slotProps={{
                      toolbar: {
                        printOptions: {
                          pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); }',
                        }
                      }
                    }}
                    sx={{
                      '--DataGrid-overlayHeight': '300px',
                      '@media print': {
                        '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
                      },
                    }}
                    initialState={{
                      pinnedColumns: {
                        left: ['action', 'companyName'],
                      },
                      pagination: {
                        paginationModel: {
                          pageSize: 50,
                        },
                      },
                    }}
                    pageSizeOptions={[50, 100, 200]}
                    disableRowSelectionOnClick
                  />
                )
              }
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default CompaniesPage
