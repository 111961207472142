import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import './DashboardPage.scss'
import {CircularProgress, Grid, Link, useTheme} from '@mui/material'
import favicon from '../../assets/configFiles/logo.png'
import {Helmet} from 'react-helmet'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import {useEffect, useState} from 'react'
import OpenAI from 'openai'
import ApiHelper from '../../helpers/ApiHelper/ApiHelper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ReactDOMServer from 'react-dom/server'
import Button from '@mui/material/Button'
import ServicesEnum from '../../enums/ServicesEnum/ServicesEnum'

const DashboardPage = () => {
  const theme = useTheme()
  const seo = theme.config.seo
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_AI_KEY,
    dangerouslyAllowBrowser: true
  })
  const [html, setHtml] = useState(null)
  const [resKnowledge, setResKnowledge] = useState(null)
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    arrayNotExist: {
      fontSize: '16px',
      marginTop: '20px',
      color: '#2C296A',
      background: '#ededed',
      padding: '20px',
      width: 'auto',
      marginLeft: '20px',
      borderRadius: '50px'
    },
    title: {
      fontSize: '22px',
      fontWeight: '600',
      marginBottom: '20px',
      color: 'black !important'
    },
    description: {
      fontSize: '16px',
      fontWeight: '400',
      marginBottom: '20px',
      whiteSpace: 'pre-wrap',
      color: 'black !important'
    },
    calendly: {
      fontSize: '16px',
      fontWeight: '400',
      marginBottom: '50px',
      whiteSpace: 'pre-wrap',
      color: 'black !important'
    },
    text: {
      color: 'black !important'
    },
    img: {
      width: '100px',
      height: '100px',
      fitObject: 'cover',
      borderRadius: '50%'
    },
    footer: {
      marginTop: '-10px'
    }
  }
  const formData = {
    elements: [
      {
        name: 'title',
        label: 'Nazwa usługi do wykonania',
        type: 'autocomplete',
        options: ServicesEnum.getAllItems(),
      },
      {
        type: 'button',
        value: 'Wygeneruj'
      }
    ]
  }
  const requestKnowledge = async (value) => {
    if (value?.length > 0) {
      const sendData = {
        model: 'gpt-3.5-turbo',
        messages: [{role: 'user', content: value}],
        temperature: 0.7,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 1000
      }
      if (sendData) {
        ApiHelper.post('https://api.openai.com/v1/chat/completions', sendData).then((res) => {
          if (res?.data) {
            const data = res?.data
            const message = data.choices[0].message.content
            message.replace('\\n', '<br/>')
            message.replace('\n', '<br>')
            setResKnowledge(message)
            setLoading(false)
          }
        })
      }
    }
  }
  const handleSubmit = (e) => {
    setLoading(true)
    setResKnowledge(null)
    setTitle(e?.title)
    let question = `Wygeneruj ofertę na ${e?.title}, bez tytułu samą ofertę.`
    question += ' Do każdej oferty podpisuj się jako marka Your Site oraz właściciel Jan Szymański.'
    question += ' Pisz w bezokolicznikach,a nie że dostarczymy, przeanalizujemy, tylko w stylu: kosztuje tyle i tyle.'
    question += ' Przedstaw koszty biorąc pod uwagę koszt 100 zł netto za godzinę oraz kwotę brutto. Zlicz ilość poświęconych godzin na całość wyceny.'
    question += ' W punktach wypisz każdą część wyceny oraz do każdej z nich dopisz cenę o przeliczając na podstawie stawki kwotę za godzinę netto'
    if (e?.title.toLowerCase().search('aplikacja webowa') >= 0) {
      question += ' Analiza i projektowanie aplikacji webowej: to max. 5 godzin'
      question += ' Jeżeli wykonywana jest aplikacja webowa uwzględnij: nie bierz pod uwagę do wyceny projektowania interfejsu, optymalizacji i wdrożenia to jest w cenie'
      question += ' Jeżeli wykonywana jest aplikacja webowa uwzględnij informację: \n' +
        'W ramach dodatkowych kosztów jest pokrycie domeny i serwera przez klienta, dopisz je do kosztów wyceny:\n' +
        '• Domena: https://www.ovhcloud.com/pl/domains/\n' +
        '• Serwer VPS: https://www.ovhcloud.com/pl/vps/ (istnieje możliwość skorzystania z infrastruktury moich serwerów jest to koszt 50 zł netto / rok).'
      question += ' Jeżeli realizowana jest aplikacja webowa to min. czas realizacji to 14 dni (nie pisz o tym w ofercie, to jest informacja tylko dla ciebie do przeliczeń) oraz średni koszt wykonania to ok. 4500 zł netto (nie pisz, że jest to min. 14 dni, tylko wypisz konkretną liczbę godzin i czas realizacji (nie wyceny))'
    }
    if (e?.title.toLowerCase().search('logo') >= 0) {
      question += ' Jeżeli realizowane jest logo to czas realizacji to 3 dni i zawsze kosztuje 150 zł netto.'
    }
    question += ' Biorąc pod uwagę ilość godzin oraz że średnio dziennie mogę poświęcić 2 godziny 5 dni w tygodniu, to przedstaw w jakim maksymalnie czasie zostanie wykonane zlecenie. (zrób podsumowanie czasu na koniec, jeżeli wartość jest ponad 4 tygodnie określ wartość również w miesiącach).'
    question += ' Biorąc pod uwagę ilość oraz koszty dodatkowe godzin określ koszt wykonania zlecenia. (zrób podsumowanie z pełnymi szczegółami i danymi ogólnymi wraz z kwotami netto oraz brutto). Jestem VAT-owcem zatem uwzględnij to w ofercie.'
    question += ' Końcowo dopisz: Faktura zostanie wystawiona na koniec zlecenia, zostanie podpisana umowa o współpracy B2B oraz na koniec projektu zostaną przekazane prawa autorskie majątkowe.'
    question?.length > 0 && e?.title && requestKnowledge(question)
  }
  const getHTML = () => {
    const view = ReactDOMServer.renderToString(html)
    navigator.clipboard.writeText(view)
  }
  useEffect(() => {
    if (resKnowledge && title) {
      setHtml((
        <Box className="offerDone">
          <Typography sx={style.title}><b>Wycena do projektu:</b> {title}</Typography>
          <Typography sx={style.description} dangerouslySetInnerHTML={{ __html: resKnowledge }}/>
          <Typography sx={style.calendly}>
            Jak masz jakiekolwiek pytania napisz emaila, bądź umów się na spotkanie 30 min, gdzie możemy wszystko omówić online:
            <Link sx={{ marginLeft: '8px' }} href="https://calendly.com/yoursitepoland/spotkanie" target="_blank">https://calendly.com/yoursitepoland/spotkanie</Link>
          </Typography>
          <Grid container spacing={4}>
            <Grid item>
              <img style={style.img} src="https://your-site.pl/assets/img/logo/logo.png" alt="image"/>
            </Grid>
            <Grid item>
              <Box sx={style.footer}>
                <Typography sx={style.text}><b>Dane kontaktowe</b></Typography>
                <Typography sx={style.text}><b>Napisz</b>: <a href="mailto:kontakt@your-site.pl" target="_blank" rel="noreferrer">kontakt@your-site.pl</a></Typography>
                <Typography sx={style.text}><b>Zadzwoń</b>: <a href="tel:+48509609439" target="_blank" rel="noreferrer">+48 509 609 439</a></Typography>
                <Typography sx={style.text}><b>Portfolio:</b> <a href="https://your-site.pl" target="_blank" rel="noreferrer">https://your-site.pl</a></Typography>
                <Typography sx={style.text}><b>LinkedIn:</b> <a href="https://www.linkedin.com/in/jan-szyma%C5%84ski-96953180" target="_blank" rel="noreferrer">https://www.linkedin.com/in/jan-szyma%C5%84ski-96953180</a></Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))
    }
  }, [resKnowledge, title])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {seo.title}
        </title>
        <link
          rel="icon"
          href={favicon}
        />
      </Helmet>
      <div style={style.root}>
        <Grid container spacing={4}>
          {
            !loading ? html ? (
              <Grid item md={6} xs={12}>
                {html}
              </Grid>
            ) : (
              <Grid item md={6} xs={12}>
                <Typography>Brak wygenerowanej wyceny, wpisz usługę do wykonania ...</Typography>
              </Grid>
              ) : (
              <Grid item md={6} xs={12}>
                <CircularProgress />
              </Grid>
            )
          }
          <Grid item md={6} xs={12}>
            <FormGenerator data={formData} submit={(e) => handleSubmit(e)}/>
            {
              !loading && html && (
                <Button sx={{ marginTop: '20px' }} variant="contained" fullWidth onClick={() => getHTML()}>Skopiuj ofertę (html)</Button>
              )
            }
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default DashboardPage
