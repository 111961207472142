import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '@draft-js-plugins/image/lib/plugin.css'
import createImagePlugin from '@draft-js-plugins/image'

const EditorText = ({ defaultValue, onChange, label, small }) => {
  const [value, setValue] = useState(defaultValue || '')
  const imagePlugin = createImagePlugin()
  return (
    <div className={small ? 'smallEditor' : ''}>
      <Editor
        placeholder={label}
        editorState={value}
        plugins={[imagePlugin]}
        onEditorStateChange={(e) => {
          setValue(e)
          onChange(value)
        }}
      />
    </div>
  )
}

export default EditorText
