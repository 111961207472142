import {Box, CircularProgress} from '@mui/material'
import {useEffect, useState} from 'react'
import './MagicLoader.scss'

const MagicLoader = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const style = {
    root: {
      position: 'absolute',
      zIndex: 999,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fdfeff',
      height: '100vh',
      overflow: 'hidden'
    }
  }
  useEffect(() => {
    setIsLoading(true)
    const showLoading = setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    return () => clearTimeout(showLoading)
  }, [])
  return (
    <>
      {
        isLoading
          ? (
            <Box className="MagicLoader" sx={style.root}>
              <CircularProgress/>
            </Box>
          )
          : (
            <>
              {props.children}
            </>
          )
      }
    </>
  )
}

export default MagicLoader
