import {useEffect, useState} from 'react'
import {DropzoneDialog} from 'mui-file-dropzone'
import Button from '../Forms/Button/Button'
import {Box, CircularProgress, Typography} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import {uploadDataToUserStorage} from '../../../firebase'
import {useSnackbar} from 'notistack'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import StorageHelper from '../../../helpers/StorageHelper/StorageHelper'
import SkeletonLoader from '../SkeletionLoader/SkeletonLoader'

const t = LocalStorageHelper.get('translation')

const DropzoneArea = ({
                        callback,
                        buttonName,
                        filesLimit = 100,
                        name = filesLimit === 1 ? t?.globals?.components?.DropzoneArea?.chooseFile : t?.globals?.components?.DropzoneArea?.chooseFiles,
                        showPreviews = true,
                        showPreviewsInDropzone = false,
                        showFileNames = true,
                        showFileNamesInPreview = true,
                        useChipsForPreview = false,
                        sizeFileLimit,
                        urlUpload = false,
                        ext = null,
                        label = '',
                        pathName
                      }) => {
  const idElement = window.location.pathname.split('/')[2]
  const {enqueueSnackbar} = useSnackbar()
  const theme = useTheme()
  const sizeFile = sizeFileLimit || 1000000000
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [url, setUrl] = useState(urlUpload || '')
  const [files, setFiles] = useState(null)
  const handleClose = () => setOpen(false)
  const handleSave = (files) => {
    setIsLoading(true)
    setFiles(files)
    setOpen(false)
  }
  const handleOpen = () => setOpen(true)
  const handleExt = () => {
    if (ext === 'csv') return ['.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .txt']
    else if (ext === 'xml') return ['.xml', '.rss']
    else if (ext === 'json') return ['.json']
    else if (ext === 'images') return ['image/*']
    else return ['image/*', 'video/*', 'application/*', '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values']
  }
  const generateUrlWithoutUid = (nameWithUid) => {
    let onlyName = ''
    nameWithUid.split('_')?.map((el, index) => {
      if (index !== 0) {
        onlyName += el
      }
      return true
    })
    return onlyName
  }
  const generateUrlToShow = (url) => {
    let showUrls = ''
    if (url?.length > 0) {
      if (url.search('services') > 0) {
        showUrls += generateUrlWithoutUid(StorageHelper.getPathNameFromUrl(url, 'files'))
      } else {
        showUrls += generateUrlWithoutUid(StorageHelper.getPathNameFromUrl(url, 'users'))
      }
    }
    return showUrls
  }
  useEffect(() => {
    if (files) {
      uploadDataToUserStorage(files, url === urlUpload ? null : url, pathName, idElement)
        .catch(() => enqueueSnackbar(t?.globals?.components?.DropzoneArea?.toasts?.error, {
          variant: 'error',
          persist: false
        }))
        .then(async (res) => {
          if (res) {
            const tempUrls = []
            await res?.forEach(async (el) => {
              const element = await el
              tempUrls.push(element)
            })
            await setIsLoading(false)
            const getUrl = setTimeout(() => setUrl(tempUrls), 1000)
            return () => clearTimeout(getUrl)
          }
        })
    }
  }, [files])
  useEffect(() => {
    if (callback && url?.length > 0) callback(url)
  }, [url, urlUpload])
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      {
        label?.length > 0 && (
          <Typography sx={{ fontSize: '16px' }}>{label}</Typography>
        )
      }
      <Button sx={{maxWidth: '320px', marginTop: '10px', color: 'white !important'}} onClick={handleOpen.bind(this)}>
        <Typography sx={{color: '#ffffff'}}>
          {buttonName || name}
        </Typography>
      </Button>
      <Typography
        sx={{
          marginTop: '10px',
          fontSize: '16px',
          display: 'inline-grid',
          color: '#3b3636',
          marginBottom: url ? '5px' : '25px'
        }}
      >
        {
          ext !== 'images' ? <span>{t?.globals?.components?.DropzoneArea?.possibleToLoad}: .{ext}.&nbsp;</span>
            : <span>{t?.globals?.components?.DropzoneArea?.possibleToLoad}: .png, .jpg, .jpeg.&nbsp;</span>
        }
        {t?.globals?.components?.DropzoneArea?.sizeFile}: {sizeFile / 1000000}MB.
      </Typography>
      {
        url?.length > 0 && ext === 'images' && (
          <img src={url} style={{height: '250px', marginBottom: '10px'}} alt="imagePreview"/>
        )
      }
      {
        url?.length > 0 && (
          <>
            {
              isLoading
                ? <CircularProgress sx={{color: '#2e7d32'}}/>
                : (
                  <SkeletonLoader variable={url?.length} time={1500} count={2}>
                    <Box
                      sx={{marginBottom: '30px', color: theme.palette.toasts.success}}
                      dangerouslySetInnerHTML={{__html: generateUrlToShow(url)}}
                    />
                  </SkeletonLoader>
                )
            }
          </>
        )
      }
      <DropzoneDialog
        open={open}
        onSave={handleSave.bind(this)}
        acceptedFiles={handleExt()}
        filesLimit={filesLimit}
        dropzoneText={t?.globals?.components?.DropzoneArea?.dragAndDrop}
        previewText={t?.globals?.components?.DropzoneArea?.listFiles}
        cancelButtonText={t?.globals?.components?.DropzoneArea?.cancel}
        submitButtonText={t?.globals?.components?.DropzoneArea?.acceptAdd}
        getFileLimitExceedMessage={() => `${t?.globals?.components?.DropzoneArea?.maxSizeFile} ${sizeFile}.`}
        getFileAddedMessage={(fileName) => `${t?.globals?.components?.DropzoneArea?.addSuccess} ${fileName}.`}
        getFileRemovedMessage={(fileName) => `${t?.globals?.components?.DropzoneArea?.deleteSuccess} ${fileName}.`}
        getDropRejectMessage={() => `${t?.globals?.components?.DropzoneArea?.maxSizeFile} ${sizeFile / 1000000}MB.`}
        dropzoneClass="dropzoneComponent"
        dropzoneParagraphClass="dropzoneText"
        showPreviews={showPreviews}
        showPreviewsInDropzone={showPreviewsInDropzone}
        showFileNames={showFileNames}
        showFileNamesInPreview={showFileNamesInPreview}
        useChipsForPreview={useChipsForPreview}
        maxFileSize={sizeFile}
        onClose={handleClose.bind(this)}
      />
    </Box>
  )
}

export default DropzoneArea
