export default {
  toTrimSpaceStartAndEnd: (name) => name.trim(),
  toLowerCase: (name) => name.toLowerCase(),
  toCapitalize: (name) => name ? name.charAt(0).toUpperCase() + name.slice(1) : '',
  toChangeAllSpacesToDash: (name) => name.split(' ').join('-'),
  toOnlyLetters: (name) => name.replace(/[0-9]/g, ''),
  streetAddress: (name) => name.replace('/^(?=.*[A-Za-z])(?=.*\\d)(?!.*[^A-Za-z0-9\\-#\\.\\/])/', ''),
  toRemoveSpecialChars: (name) => name.replace(/[^a-zA-Z0-9 ]/g, ''),
  toDisableCharsPL: (name) => {
    name = name.split('Ą').join('A')
    name = name.split('ą').join('a')
    name = name.split('ć').join('c')
    name = name.split('Ć').join('C')
    name = name.split('ę').join('e')
    name = name.split('Ę').join('E')
    name = name.split('ł').join('l')
    name = name.split('Ł').join('L')
    name = name.split('ń').join('n')
    name = name.split('Ń').join('N')
    name = name.split('ó').join('o')
    name = name.split('Ó').join('O')
    name = name.split('ś').join('s')
    name = name.split('Ś').join('S')
    name = name.split('ż').join('z')
    name = name.split('Ż').join('Z')
    name = name.split('ź').join('z')
    name = name.split('Ź').join('Z')
    return name
  },
  removeLastChars: (str, chars) => str.substring(0, str.length - chars),
  changeStringToStars: (str) => Array(str.length).join('*'),
  generateRandomHash: () => {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < 5; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))
    return text
  },
  add3Dots: (string, limit) => {
    const dots = '...'
    if (string.length > limit) {
      string = string.substring(0, limit) + dots
    }
    return string
  }
}
