import Typography from '@mui/material/Typography'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import {Grid} from '@mui/material'
import Box from '@mui/material/Box'
import {useEffect, useState} from 'react'
import Tabs from '../../components/molecules/Tabs/Tabs'

const AIPage = () => {
  const [dataTabs, setDataTabs] = useState([])
  const style = {
    root: {
      padding: '50px',
    },
    box: {
      minHeight: '730px !important',
    }
  }
  useEffect(() => {
    const allTabs = []
    allTabs.push({
      name: 'Napisz posta',
      value: () => (
        <Grid container>
          <Grid item md={4}>
            <FormGenerator
              data={{
                elements: [
                  {
                    name: 'topic',
                    label: 'Temat posta',
                  },
                  {
                    name: 'event',
                    label: 'Wybierz rodzaj wydarzenia',
                  },
                  {
                    name: 'style',
                    type: 'select',
                    label: 'Wybierz styl tekstu',
                    items: [
                      {value: 'Neutralny', name: 'Neutralny'},
                      {value: 'Humorystyczny', name: 'Humorystyczny'},
                      {value: 'Poważny', name: 'Poważny'},
                      {value: 'Ekspercki', name: 'Ekspercki'},
                      {value: 'Krótki', name: 'Krótki'},
                      {value: 'Długi', name: 'Długi'},
                      {value: 'Na luzie', name: 'Na luzie'},
                      {value: 'Profesjonalny', name: 'Profesjonalny'},
                      {value: 'Budzący ciekawość', name: 'Budzący ciekawość'},
                      {value: 'Czy wiedziałeś, że', name: 'Czy wiedziałeś, że'},
                      {value: 'Neutralizujący obiekcje', name: 'Neutralizujący obiekcje'},
                      {value: 'Zawierający storytelling', name: 'Zawierający storytelling'},
                    ]
                  },
                  {
                    name: 'count',
                    type: 'select',
                    label: 'Wybierz ilość znaków',
                    items: [
                      {value: '500', name: '500'},
                      {value: '1000', name: '1000'},
                      {value: '2000', name: '2000'},
                      {value: '2500', name: '2500'},
                      {value: '5000', name: '5000'},
                    ]
                  },
                  {
                    name: 'emoji',
                    label: 'Dodaj emoji',
                    type: 'checkbox',
                  },
                  {
                    name: 'subscribe',
                    label: 'Dodaj wezwanie do obserwowania strony',
                    type: 'checkbox',
                  },
                  {
                    name: 'hashtags',
                    label: 'Dodaj hashtagi',
                    type: 'checkbox',
                  },
                  {
                    name: 'question',
                    label: 'Zadaj pytanie',
                    type: 'checkbox',
                  },
                  {
                    type: 'button',
                    value: 'Zapisz',
                  }
                ]
              }}
              submit={(e) => console.log(e)}
            />
          </Grid>
        </Grid>
      ),
    })
    allTabs.push({
      name: 'Komentarz do posta',
      value: () => (
        <Grid container>
          <Grid item md={4}>
            <FormGenerator
              data={{
                elements: [
                  {
                    name: 'name',
                    label: 'Wpisz informacje na temat komentarza',
                  },
                  {
                    name: 'style',
                    type: 'select',
                    label: 'Wybierz styl tekstu',
                    items: [
                      {value: 'Neutralny', name: 'Neutralny'},
                      {value: 'Humorystyczny', name: 'Humorystyczny'},
                      {value: 'Poważny', name: 'Poważny'},
                      {value: 'Ekspercki', name: 'Ekspercki'},
                      {value: 'Krótki', name: 'Krótki'},
                      {value: 'Długi', name: 'Długi'},
                      {value: 'Na luzie', name: 'Na luzie'},
                      {value: 'Profesjonalny', name: 'Profesjonalny'},
                      {value: 'Budzący ciekawość', name: 'Budzący ciekawość'},
                      {value: 'Czy wiedziałeś, że', name: 'Czy wiedziałeś, że'},
                      {value: 'Neutralizujący obiekcje', name: 'Neutralizujący obiekcje'},
                      {value: 'Zawierający storytelling', name: 'Zawierający storytelling'},
                    ]
                  },
                  {
                    name: 'emoji',
                    label: 'Dodaj emoji',
                    type: 'checkbox',
                  },
                  {
                    name: 'subscribe',
                    label: 'Dodaj wezwanie do obserwowania strony',
                    type: 'checkbox',
                  },
                  {
                    name: 'hashtags',
                    label: 'Dodaj hashtagi',
                    type: 'checkbox',
                  },
                  {
                    type: 'button',
                    value: 'Zapisz',
                  }
                ]
              }}
              submit={(e) => console.log(e)}
            />
          </Grid>
        </Grid>
      )
    })
    allTabs.push({
      name: 'Frazy kluczowe',
      value: () => (
        <Grid container>
          <Grid item md={4}>
            <FormGenerator
              data={{
                elements: [
                  {
                    name: 'name',
                    label: 'Wpisz frazę',
                  },
                  {
                    type: 'button',
                    value: 'Zapisz',
                  }
                ]
              }}
              submit={(e) => console.log(e)}
            />
          </Grid>
        </Grid>
      )
    })
    allTabs.push({
      name: 'Pomysł na biznes',
      value: () => (
        <Grid container>
          <Grid item md={4}>
            <FormGenerator
              data={{
                elements: [
                  {
                    name: 'name',
                    label: 'Podaj problem biznesowy, który chcesz rozwiązać',
                  },
                  {
                    name: 'style',
                    type: 'select',
                    label: 'Wybierz styl tekstu',
                    items: [
                      {value: 'Neutralny', name: 'Neutralny'},
                      {value: 'Humorystyczny', name: 'Humorystyczny'},
                      {value: 'Poważny', name: 'Poważny'},
                      {value: 'Ekspercki', name: 'Ekspercki'},
                      {value: 'Krótki', name: 'Krótki'},
                      {value: 'Długi', name: 'Długi'},
                      {value: 'Na luzie', name: 'Na luzie'},
                      {value: 'Profesjonalny', name: 'Profesjonalny'},
                      {value: 'Budzący ciekawość', name: 'Budzący ciekawość'},
                      {value: 'Czy wiedziałeś, że', name: 'Czy wiedziałeś, że'},
                      {value: 'Neutralizujący obiekcje', name: 'Neutralizujący obiekcje'},
                      {value: 'Zawierający storytelling', name: 'Zawierający storytelling'},
                    ]
                  },
                  {
                    name: 'person',
                    type: 'select',
                    label: 'Wybierz mentora',
                    items: [
                      {value: 'Jeff Bezos - Amazon', name: 'Jeff Bezos - Amazons'},
                      {value: 'Elon Musk - Tesla, SpaceX', name: 'Elon Musk - Tesla, SpaceX'},
                      {value: 'Bill Gates - Microsoft', name: 'Bill Gates Microsoft'},
                    ]
                  },
                  {
                    type: 'button',
                    value: 'Zapisz',
                  }
                ]
              }}
              submit={(e) => console.log(e)}
            />
          </Grid>
        </Grid>
      )
    })
    allTabs.push({
      name: 'Problemy biznesowe - coach',
      value: () => (
        <Grid container>
          <Grid item md={4}>
            <FormGenerator
              data={{
                elements: [
                  {
                    name: 'name',
                    label: 'Podaj problem biznesowy, który chcesz rozwiązać',
                  },
                  {
                    name: 'style',
                    type: 'select',
                    label: 'Wybierz styl tekstu',
                    items: [
                      {value: 'Neutralny', name: 'Neutralny'},
                      {value: 'Humorystyczny', name: 'Humorystyczny'},
                      {value: 'Poważny', name: 'Poważny'},
                      {value: 'Ekspercki', name: 'Ekspercki'},
                      {value: 'Krótki', name: 'Krótki'},
                      {value: 'Długi', name: 'Długi'},
                      {value: 'Na luzie', name: 'Na luzie'},
                      {value: 'Profesjonalny', name: 'Profesjonalny'},
                      {value: 'Budzący ciekawość', name: 'Budzący ciekawość'},
                      {value: 'Czy wiedziałeś, że', name: 'Czy wiedziałeś, że'},
                      {value: 'Neutralizujący obiekcje', name: 'Neutralizujący obiekcje'},
                      {value: 'Zawierający storytelling', name: 'Zawierający storytelling'},
                    ]
                  },
                  {
                    name: 'person',
                    type: 'select',
                    label: 'Wybierz mentora',
                    items: [
                      {value: 'Jeff Bezos - Amazon', name: 'Jeff Bezos - Amazons'},
                      {value: 'Elon Musk - Tesla, SpaceX', name: 'Elon Musk - Tesla, SpaceX'},
                      {value: 'Bill Gates - Microsoft', name: 'Bill Gates Microsoft'},
                    ]
                  },
                  {
                    type: 'button',
                    value: 'Zapisz',
                  }
                ]
              }}
              submit={(e) => console.log(e)}
            />
          </Grid>
        </Grid>
      )
    })
    setDataTabs(allTabs)
  }, [])
  return (
    <Box sx={style.root}>
      <Tabs data={dataTabs} />
    </Box>
  )
}

export default AIPage
