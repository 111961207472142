import {Grid} from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import {useSnackbar} from 'notistack'
import ServicesEnum from '../../enums/ServicesEnum/ServicesEnum'
import Button from '@mui/material/Button'
import {updateOrCreateDocument} from '../../firebase'

const QuotationPage = () => {
  const {enqueueSnackbar} = useSnackbar()
  const formData = {
    elements: [
      {
        name: 'name',
        label: 'Imię i nazwisko',
      },
      {
        name: 'nip',
        label: 'NIP (o ile firma)',
        required: false,
      },
      {
        name: 'companyName',
        label: 'Nazwa firmy',
        required: false,
      },
      {
        name: 'email',
        label: 'Email',
      },
      {
        name: 'phone',
        label: 'Telefon',
      },
      {
        name: 'services',
        label: 'Nazwa usługi do wykonania',
        type: 'autocomplete',
        multiple: true,
        options: ServicesEnum.getAllItems(),
      },
      {
        name: 'description',
        label: 'Opis wymagań',
        type: 'editor',
        small: true,
      },
      {
        type: 'button',
        value: 'Zapisz'
      }
    ]
  }
  const handleData = (e) => {
    e.date = new Date()
    updateOrCreateDocument('quotations', null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Emailem dostaniesz szczegółową wycenę, skontaktuję się najszybciej jak to możliwe.', {variant: 'success'})
      }
    })
  }
  return (
    <Box sx={{ display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
            <Typography variant="h2" sx={{fontWeight: '600'}}>Wycena Twojego projektu</Typography>
            <Typography variant="h5" sx={{fontWeight: '400', marginTop: '20px', marginBottom: '20px'}}>Moja pasją jest tworzenie innowacyjnego oprogramowania, w którym dbam o najwyższy poziom profesjonalizmu.</Typography>
            <Box sx={{ display: 'flex' }}>
              <Button sx={{ marginRight: '20px' }} variant="outlined" onClick={() => window.open('https://your-site.pl/portfolio-2', '_self')}>Moje portfolio</Button>
              <Button variant="contained" onClick={() => window.open('https://calendly.com/yoursitepoland/spotkanie', '_self')}>Umów 30 min. konsultację</Button>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
            <FormGenerator data={formData} submit={(e) => handleData(e)}/>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default QuotationPage
