import {useEffect, useState} from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import logo from '../../../assets/configFiles/logo.png'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import {Menu, MenuItem} from '@mui/material'
import Button from '@mui/material/Button'
import {getAllDataFromPath} from '../../../firebase'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

const NavigationPreview = () => {
  const [open, setOpen] = useState(false)
  const [silos, setSilos] = useState(null)
  const [categories, setCategories] = useState(null)
  const style = {
    nav: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: '100%',
      height: '90px',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 999,
      boxShadow: '2px 2px 20px 10px rgba(0, 0, 0, 0.02)'
    },
    logo: {
      maxHeight: '60px',
      maxWidth: '320px',
      cursor: 'pointer',
      marginLeft: '30px',
    },
    menu: {
      width: 30,
      height: 30,
      color: '#2C296A',
      cursor: 'pointer',
      marginRight: '30px',
    },
    logoOpen: {
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '35px',
      position: 'relative',
      left: '-5px',
      bottom: '-3px'
    },
    buttons: {
      display: 'flex',
      alignItems: 'center'
    },
    burgerOpen: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      right: '-5px',
      bottom: '-2px',
      marginBottom: '30px'
    },
    elementNav: {
      fontSize: '18px',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '40px',
      cursor: 'pointer',
      border: BreakpointsHelper.isMobile() && '1px solid black',
      padding: BreakpointsHelper.isMobile() && '20px',
      textAlign: BreakpointsHelper.isMobile() && 'center',
      marginBottom: BreakpointsHelper.isMobile() && '20px'
    },
    menuRight: {
      display: 'flex'
    }
  }
  const openLink = (link) => window.open(link, '_self')
  const generateMenu = () => {
    const pages = [
      {
        name: 'Strona Główna',
        link: '/'
      },
    ]
    silos?.forEach(el => {
      const data = el?.data
      if (data?.silos === 'menu') {
        const menu = {
          name: data?.name,
          link: '/',
          ul: []
        }
        categories?.forEach(elCat => {
          const dataCat = elCat?.data
          if (dataCat?.silos === data?.name) {
            menu.ul.push({
              name: dataCat?.name,
              link: `/showCategories/${elCat?.id}`,
            })
          }
        })
        pages.push(menu)
      }
    })
    const offer = {
      name: 'Oferta',
      link: '/',
      ul: []
    }
    silos?.forEach(el => {
      const data = el?.data
      if (data?.silos === 'oferta') {
        offer.ul.push({
          name: data?.name,
          link: `/showCategories/${el?.id}`,
        })
      }
    })
    pages.push(offer)
    const blog = {
      name: 'Blog',
      link: '/'
    }
    silos?.forEach(el => {
      const data = el?.data
      if (data?.silos === 'blog') {
        blog.ul.push({
          name: data?.name,
          link: `/showCategories/${el?.id}`,
        })
      }
    })
    pages.push(blog)
    pages.push(
      {
        name: 'Kontakt',
        link: '/contact'
      })
    return (
      <header style={{display: 'flex'}}>
        <Box sx={{flexGrow: 1, display: 'flex', flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row', alignItems: 'flex-start'}}>
          {
            pages.map((page, index) => {
              if (page?.ul?.length > 0) {
                return (
                  <>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <>
                          <Button
                            sx={{ fontSize: BreakpointsHelper.isMobile() ? '30px' : '14px'}}
                            variant="text"
                            {...bindTrigger(popupState)}
                          >
                            {page?.name || ''}
                          </Button>
                          <Menu {...bindMenu(popupState)}>
                            {
                              page?.ul?.map((element, key) => (
                                <MenuItem
                                  key={key}
                                  sx={{textTransform: 'uppercase'}}
                                  onClick={() => openLink(element.link)}
                                >
                                  {element.name}
                                </MenuItem>
                              ))
                            }
                          </Menu>
                        </>
                      )}
                    </PopupState>
                  </>
                )
              }
              return (
                <Button
                  key={index}
                  onClick={() => openLink(page.link)}
                  variant="text"
                  className={window.location.pathname === page.link && 'active'}
                  sx={{
                    fontSize: BreakpointsHelper.checkSizeWindow('lg', 'down') ? '12px' : '14px',
                    marginLeft: '5px',
                  }}
                >
                  {page.name}
                </Button>
              )
            })
          }
        </Box>
      </header>
    )
  }
  useEffect(() => {
    getAllDataFromPath('silos').then((res) => {
      if (res) {
        setSilos(res)
      }
    })
    getAllDataFromPath('categories').then((res) => {
      if (res) {
        setCategories(res)
      }
    })
  }, [])
  return (
    <div style={style.nav} className="navigation">
      <Box
          sx={{
            maxWidth: '1680px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            margin: '0 auto',
        }}
      >
        <img src={logo} onClick={() => openLink('/home')} alt={'logo'} style={style.logo}/>
        <Box sx={style.menuRight}>
          {
            BreakpointsHelper.isMobile()
                ? (
                    <div style={style.burger} onClick={() => setOpen(!open)}>
                      <MenuIcon style={style.menu}/>
                    </div>
                )
                : silos?.length > 0 && generateMenu()
          }
          {
              open && (
                  <Box
                      sx={{
                        position: 'absolute',
                        zIndex: '999',
                        background: 'white',
                        top: '90px',
                        left: '0',
                        height: 'calc(100vh - 90px)',
                        width: 'calc(100% - 40px)',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '20px'
                      }}
                  >
                    {silos?.length > 0 && generateMenu()}
                  </Box>
              )
          }
        </Box>
      </Box>
    </div>
  )
}

export default NavigationPreview
