import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Box from '@mui/material/Box'
import {Grid} from '@mui/material'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import Typography from '@mui/material/Typography'
import {useEffect, useState} from 'react'
import StringHelper from '../../helpers/StringHelper/StringHelper'
import ArrayHelper from '../../helpers/ArrayHelper/ArrayHelper'
import SkeletonLoader from '../../components/atoms/SkeletionLoader/SkeletonLoader'

const TaxesPage = () => {
  const [allItems, setAllItems] = useState([])
  const [formData, setFormData] = useState([])
  const [current, setCurrent] = useState([])
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    }
  }
  const fixedDeductions = [
    {name: 'PHP Storm'},
    {name: 'Microsoft Office'},
    {name: 'InFakt'},
    {name: 'Chat GPT'},
    {name: 'Paliwo'},
    {name: 'Druk w drukarni'},
    {name: 'Material UI'},
    {name: 'Powiadomienia SMS'},
    {name: 'Internet'},
    {name: 'Baterie'},
    {name: 'Papier ksero'},
  ]
  const variableDeductions = [
    {name: 'Folie do segregatorów'},
    {name: 'Taśma pakowa'},
    {name: 'Segregatory'},
    {name: 'UPS'},
    {name: 'Doradztwo z prawnikiem'},
    {name: 'Doradztwo z księgowym'},
    {name: 'Dysk twardy'},
    {name: 'Template do portali internetowych'},
    {name: 'Planszówki'},
  ]
  const [data] = useState([
    {
      pkd: '62.01.Z',
      name: 'Działalność związana z oprogramowaniem',
      services: 'Aplikacje webowe, Strona internetowa, Bazy danych, Konfiguracja serwerowa, Konfiguracja chmurowa, Konfiguracja domeny, SSL,  Konfiguracja email, Aplikacja mobilna, Statystyki strony internetowej, Statystyki reklam internetowych, Analiza danych reklamowych',
      pkwiu: [
        '62.01.11.0',
        '62.01.12.0',
        '62.01.29.0'
      ],
      tax: '12%'
    },
    {
      pkd: '18.12.Z',
      name: 'Pozostałe drukowanie',
      services: 'Materiały reklamowe drukowane',
      pkwiu: [
        '18.12.12.0',
        '18.12.13.0',
        '18.12.14.0',
        '18.12.15.0',
        '18.12.16.0',
        '18.12.19.0'
      ],
      tax: '8,50%'
    },
    {
      pkd: '18.13.Z',
      name: 'Działalność usługowa związana z przygotowywaniem do druku',
      services: 'Materiały reklamowe drukowane, Plakat, Baner, Ulotka, Katalog, Logo, Druk dla firm',
      pkwiu: [
        '18.13.10.0',
        '18.13.30.0'
      ],
      tax: '8,50%'
    },
    {
      pkd: '32.40.Z',
      name: 'Produkcja gier i zabawek',
      services: 'Wykonanie gry planszowej',
      pkwiu: [
        '32.40.32.0',
        '32.40.39.0',
        '32.40.41.0',
        '32.40.99.0'
      ],
      tax: '5,50%'
    },
    {
      pkd: '47.65.Z',
      name: 'Sprzedaż detaliczna gier i zabawek prowadzona w wyspecjalizowanych sklepach',
      services: 'Sprzedaż gier planszowych od wydawnictw',
      pkwiu: [],
      tax: '3%'
    },
    {
      pkd: '47.71.Z',
      name: 'Sprzedaż detaliczna gier i zabawek prowadzona w wyspecjalizowanych sklepach',
      services: 'Sprzedaż koszulek planszówek',
      pkwiu: [],
      tax: '3%'
    },
    {
      pkd: '47.91.Z',
      name: 'Sprzedaż detaliczna prowadzona przez domy sprzedaży wysyłkowej lub Internet',
      services: 'Sprzedaż gier planszowych',
      pkwiu: [],
      tax: '3%'
    },
    {
      pkd: '58.21.Z',
      name: 'Działalność wydawnicza w zakresie gier komputerowych',
      services: 'Wydanie gry komputerowej, Wydanie gry sieciowej',
      pkwiu: [
        '58.21.10.0',
        '58.21.20.0 ',
        '58.21.30.0',
        '58.21.40.0'
      ],
      tax: '12%'
    },
    {
      pkd: '58.29.Z',
      name: 'Działalność wydawnicza w zakresie pozostałego oprogramowania',
      services: 'Wydanie oprogramowania z abonamentem',
      pkwiu: [
        '58.29.11.0',
        '58.29.12.0',
        '58.29.13.0',
        '58.29.14.0',
        '58.29.21.0',
        '58.29.29.0',
        '58.29.40.0'
      ],
      tax: '12%'
    },
    {
      pkd: '59.12.Z',
      name: 'Działalność postprodukcyjna związana z filmami, nagraniami wideo i programami telewizyjnymi',
      services: 'Projekty wideo, Animacje komputerowe',
      pkwiu: [
        '59.12.11.0 ',
        '59.12.12.0',
        '59.12.13.0',
        '59.12.14.0',
        '59.12.15.0',
        '59.12.16.0',
        '59.12.17.0',
        '59.12.19.0 '
      ],
      tax: '8,50%'
    },
    {
      pkd: '62.02.Z',
      name: 'Działalność związana z doradztwem w zakresie informatyki',
      services: 'Spotkania z doradztwem w zakresie informatyki, Analiza i doradztwo w zakresie informatyki',
      pkwiu: [
        '62.02.10.0',
        '62.02.20.0',
        '62.02.30.0'
      ],
      tax: '12%'
    },
    {
      pkd: '62.03.Z',
      name: 'Działalność związana z zarządzaniem urządzeniami informatycznymi',
      services: 'Naprawianie komputerów u klienta',
      pkwiu: [
        '62.03.11.0 ',
        '62.03.12.0'
      ],
      tax: '12%'
    },
    {
      pkd: '62.09.Z',
      name: 'Pozostała działalność usługowa w zakresie technologii informatycznych i komputerowych',
      services: 'Usługi odzyskiwania danych u klienta',
      pkwiu: [
        '62.09.10.0',
        '62.09.20.0'
      ],
      tax: '12%'
    },
    {
      pkd: '63.11.Z',
      name: 'Przetwarzanie danych; zarządzanie stronami internetowymi (hosting) i podobna działalność',
      services: 'Administracja stroną internetową, Administracja aplikacją webową, Udostępnianie własnych serwerów, Miejsce na reklamę w internecie',
      pkwiu: [
        '63.11.11.0',
        '63.11.12.0',
        '63.11.13.0',
        '63.11.19.0',
        '63.11.20.0'
      ],
      tax: '8,50%'
    },
    {
      pkd: '63.12.Z',
      name: 'Działalność portali internetowych',
      services: 'Opłata za wrzucanie informacji na temat firmy na moich stronach (opłata abonamentowa)',
      pkwiu: [
        '63.12.20.0'
      ],
      tax: '15%'
    },
    {
      pkd: '63.91.Z',
      name: 'Działalność agencji informacyjnych',
      services: 'Listy adresowe w excel, Listy danych o potencjalnych klientach',
      pkwiu: [
        '63.99.10.0',
        '63.99.20.0'
      ],
      tax: '15%'
    },
    {
      pkd: '71.12.Z',
      name: 'Działalność w zakresie inżynierii i związane z nią doradztwo techniczne',
      services: 'Analiza i doradztwo uszkodzonego oprogramowania',
      pkwiu: [
        '71.12.11.0 '
      ],
      tax: '14%'
    },
    {
      pkd: '73.11.Z',
      name: 'Działalność agencji reklamowych',
      services: 'Kampanie reklamowe, Reklama w social mediach, Grafiki na materiały reklamowe',
      pkwiu: [],
      tax: '15%'
    },
    {
      pkd: '74.10.Z',
      name: 'Działalność w zakresie specjalistycznego projektowania',
      services: 'Projekt wystroju wnętrz i dekoracji, SEO, Email marketing',
      pkwiu: [
        '74.10.11.0 ',
        '74.10.19.0',
        '74.10.20.0'
      ],
      tax: '14%'
    },
    {
      pkd: '82.30.Z',
      name: 'Działalność związana z organizacją targów, wystaw i kongresów',
      services: '',
      pkwiu: [
        '82.30.11.0',
        '82.30.12.0'
      ],
      tax: '15%'
    },
    {
      pkd: '85.59.B',
      name: 'Pozostałe pozaszkolne formy edukacji, gdzie indziej niesklasyfikowane',
      services: 'Prowadzenie szkoleń z programowania lub AI, Kursy komputerowe, Usługi w zakresie doskonalenia zawodowego nauczycieli, Korepetycje udzielane w domu, Pozaszkolna forma edukakcji',
      pkwiu: [
        '85.59.12.0 ',
        '85.59.13.1',
        '85.59.13.2',
        '85.59.14.0',
        '85.59.19.0'
      ],
      tax: '8,50%'
    },
    {
      pkd: '46.18.Z',
      name: 'Usługi pośrednictwa w sprzedaży hurtowej pozostałych określonych towarów',
      services: 'Usługi pośrednictwa w sprzedaży hurtowej gier i zabawek',
      pkwiu: [
        '46.18.12.0'
      ],
      tax: '15%'
    },
    {
      pkd: '46.19.Z',
      name: 'Działalność agentów zajmujących się sprzedażą towarów różnego rodzaju (w tym pośrednictwo)',
      services: 'Usługi pośrednictwa w sprzedaży detalicznej gier i zabawek',
      pkwiu: [],
      tax: '15%'
    }
  ])
  const handleData = (e) => {
    const service = e?.service
    data?.forEach(el => {
      console.log(el)
      if (el?.services?.search(service) >= 0) {
        setCurrent(el)
      }
    })
  }
  useEffect(() => {
    if (data) {
      const tempValues = []
      data?.forEach((el) => {
        const services = el?.services?.split(',')
        if (services[0]?.length > 0) {
          services?.forEach((serv) => {
            tempValues.push(StringHelper.toCapitalize(serv))
          })
        }
      })
      ArrayHelper.deleteDuplicate(tempValues)
      const items = []
      tempValues.forEach((el) => {
        items.push({value: el, name: el})
      })
      setAllItems(items)
    }
  }, [data])
  useEffect(() => {
    if (allItems?.length > 0) {
      setFormData({
        elements: [
          {
            name: 'service',
            type: 'select',
            label: 'Wybierz usługę',
            items: allItems,
            value: 'Zapisz'
          },
          {
            type: 'button',
            value: 'Zapisz'
          }
        ]
      })
    }
  }, [allItems])
  return (
    <Box sx={style.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Grid continer sx={{display: 'flex'}}>
            <Grid item md={6} xs={12}>
              <Typography sx={{fontWeight: '600'}}>Elementy stałe do odliczenia:</Typography>
              {
                fixedDeductions?.map((el, index) => {
                  return (
                    <Typography key={index}>{index + 1}. {el.name}</Typography>
                  )
                })
              }
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography sx={{fontWeight: '600'}}>Elementy dodatkowe do odliczenia:</Typography>
              {
                variableDeductions?.map((el, index) => {
                  return (
                    <Typography key={index}>{index + 1}. {el.name}</Typography>
                  )
                })
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <SkeletonLoader variable={formData}>
            <FormGenerator data={formData} submit={(e) => handleData(e)}/>
          </SkeletonLoader>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Wysokość podatku do opłacenia w ramach ryczałtu ewidencjonowanego. Wybierz z listy usługę i
            zatwierdź by zobaczyć wysokość podatku.</Typography>
          <Box sx={{marginTop: '20px'}}>
            {
              current && (
                <>
                  <Typography sx={{fontWeight: '600'}}>Podatek - {current?.tax}</Typography>
                  <Typography>{current?.pkd} - {current?.name}</Typography>
                  <Typography sx={{marginBottom: '20px'}}>{current?.services || ''}</Typography>
                  <Typography sx={{fontWeight: '600'}}>PKWIU</Typography>
                  {
                    current?.pkwiu?.map((el, key) => {
                      return (
                        <Typography key={key}>{el}</Typography>
                      )
                    })
                  }
                </>
              )
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TaxesPage
