import firebase from 'firebase/compat/app'
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, setDoc, updateDoc} from 'firebase/firestore'
import LocalStorageHelper from './helpers/LocalStorageHelper/LocalStorageHelper'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

// eslint-disable-next-line jest/require-hook
const app = firebase.initializeApp(firebaseConfig)

const auth = getAuth()
const db = getFirestore()

export const signOutFirebase = () => signOut(auth).then((res) => res).catch((error) => console.error(error.code, error.message))

export const getAllUsers = async () => await getAllDataFromPath('users').then((res) => res)

export const getAllReservations = async () => await getAllDataFromPath('reservations').then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      tempRes.push(tempEl)
    })
    return tempRes
  }
})

export const getAllReservationsTrips = async () => await getAllDataFromPath('reservationsTrips').then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      tempRes.push(tempEl)
    })
    return tempRes
  }
})

export const getAllCampers = async () => await getAllDataFromPath('campers').then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      tempRes.push(tempEl)
    })
    return tempRes
  }
})

export const getAllTrips = async () => await getAllDataFromPath('trips').then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      tempRes.push(tempEl)
    })
    return tempRes
  }
})

export const getAllPosts = async () => await getAllDataFromPath('blog').then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      tempRes.push(tempEl)
    })
    return tempRes
  }
})

export const getAllGalleries = async () => await getAllDataFromPath('gallery').then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      tempRes.push(tempEl)
    })
    return tempRes
  }
})

export const getPostById = async (id) => await getAllDataFromPath('blog', id).then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      if (el?.id === id) {
        tempRes.push(tempEl)
      }
    })
    return tempRes[0]
  }
})

export const getGalleryById = async (id) => await getAllDataFromPath('gallery', id).then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      if (el?.id === id) {
        tempRes.push(tempEl)
      }
    })
    return tempRes[0]
  }
})

export const getCamperById = async (id) => await getAllDataFromPath('campers', id).then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      if (el?.id === id) {
        tempRes.push(tempEl)
      }
    })
    return tempRes[0]
  }
})

export const getTripById = async (id) => await getAllDataFromPath('trips', id).then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      if (el?.id === id) {
        tempRes.push(tempEl)
      }
    })
    return tempRes[0]
  }
})

export const getReservationById = async (id) => await getAllDataFromPath('reservations', id).then((res) => {
  if (res) {
    const tempRes = []
    res?.forEach(el => {
      const tempEl = {}
      Object.keys(el?.data)?.forEach((element) => {
        tempEl[element] = el?.data[element]
      })
      tempEl.id = el?.id
      if (el?.id === id) {
        tempRes.push(tempEl)
      }
    })
    return tempRes[0]
  }
})

export const createUser = (data) => {
  return createUserWithEmailAndPassword(auth, data.email, data.password).then(async (userCredential) => {
    const user = await userCredential?.user
    if (user) {
      sendEmailVerified(user)
      return addDocument(data, user).then(res => res)
    }
  }).catch((error) => console.error(error.code, error.message))
}

export const changeDataUser = async (email, data) => {
  const docRef = doc(db, 'users', email)
  await updateDoc(docRef, {
    name: data?.name,
    phone: data?.phone,
    street: data?.street,
    apartment: data?.apartment,
    house: data?.house,
    defaultLang: data?.defaultLang
  })
  return true
}

export const sendEmailVerified = (userCredential) => {
  sendEmailVerification(userCredential).then(() => LocalStorageHelper.set('emailForSignIn', true)).catch((error) => console.error(error.code, error.message))
}

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email).then(() => true).catch((error) => console.error(error.code, error.message))
}

export const updateOrCreateDocument = async (path, pathSegment, data) => {
  if (pathSegment) await setDoc(doc(db, path, pathSegment), data)
  else await addDoc(collection(db, path), data)
  return data
}

export const uploadDataToStorage = async (files, path) => {
  const paths = []
  for (const el of files) {
    const storage = getStorage(app, firebaseConfig.storageBucketImport)
    const storageRef = ref(storage, `${path}/${(+new Date()).toString(36).slice(-8)}_${el.name}`)
    await uploadBytes(storageRef, el, el?.type).then(async (snapshot) => {
      if (snapshot) {
        paths.push(getDownloadURL(snapshot?.ref).then((downloadURL) => downloadURL || ''))
      }
    })
  }
  return paths
}

export const uploadDataToUserStorage = (files, url, pathName = null, folderName) => {
  return uploadDataToStorage(files, `${pathName || `/gallery/${folderName}/`}`).then((res) => res)
}

export const getDataFromPath = async (path, pathSegments) => {
  const docSnap = await getDoc(doc(db, path, pathSegments))
  if (docSnap.exists()) return docSnap.data()
  return {}
}

export const addDocument = async (data, user) => {
  const sendData = {
    uid: user?.uid,
    email: data?.email || user?.email || '',
    phone: data?.phone || user?.phoneNumber || '',
    name: `${data?.firstName} ${data?.secondName}` || user?.name || '',
    role: 'user'
  }
  return await updateOrCreateDocument('users', data.email || user.email, sendData).then((res) => {
    if (res) {
      LocalStorageHelper.set('user', sendData)
      return sendData
    }
  })
}

export const updateUsers = async (data) => await updateOrCreateDocument('users', data?.email, data).then(res => res)

export const getAllDataFromPath = async (path) => {
  const allDocs = []
  const querySnapshot = await getDocs(collection(db, path))
  await querySnapshot.forEach((doc) => allDocs.push({id: doc.id, data: doc.data()}))
  return allDocs
}

export const refreshToken = () => auth?.currentUser?.getIdToken()

export const deleteCollectionById = async (name, id) => await deleteDoc(doc(db, name, id))

export const loginUser = (email, password) => {
  const auth = getAuth()
  return signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
    return userCredential?.user
  }).catch((error) => console.error(error.code, error.message))
}

export default firebase
