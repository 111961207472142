import axios from 'axios'
import ErrorsHelper from '../ErrorsHelper/ErrorsHelper'

export const defaultHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  Authorization: `Bearer ${process.env.REACT_APP_AI_KEY}`
}

export default axios.create({
  headers: {
    ...defaultHeaders,
  }
})

export const getMessageError = (status) => {
  return ErrorsHelper.checkErrorCode(status)
}
