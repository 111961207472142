import PropTypes from 'prop-types'
import NavigationPreview from '../../components/molecules/NavigationPreview/NavigationPreview'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import Box from '@mui/material/Box'

const PreviewLayout = (props) => {
  const Component = props.component
  const {location} = props
  const style = {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'start',
      flexDirection: 'column',
      padding: '90px 0 0 0',
      width: '100%'
    }
  }
  return (
    <div style={style.root}>
      <NavigationPreview location={location}/>
      <MagicLoader>
        <Box sx={{ padding: '30px', minHeight: 'calc(100vh - 90px - 60px)', display: 'flex', alignItem: 'center', justifyContent: 'center', width: 'calc(100% - 60px)' }}>
          <Component location={location}/>
        </Box>
      </MagicLoader>
    </div>
  )
}

PreviewLayout.defaultProps = {
  location: false
}

PreviewLayout.propsType = {
  location: PropTypes.object
}

export default PreviewLayout
