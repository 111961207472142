import {useEffect} from 'react'
import {useTheme} from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import UserHelper from '../../helpers/UserHelper/UserHelper'
import {useSnackbar} from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import CookiesHelper from '../../helpers/CookiesHelper/CookiesHelper'
import favicon from '../../assets/configFiles/logo.png'
import {Helmet} from 'react-helmet'

const LoginPage = (props) => {
  const theme = useTheme()
  const seo = theme.config.seo
  const {enqueueSnackbar} = useSnackbar()
  const formData = {
    elements: [
      {
        name: 'email',
        type: 'email',
        label: 'Email',
        validationType: 'string'
      },
      {
        name: 'password',
        type: 'password',
        label: 'Hasło',
        helperText: 'Hasło musi się składać z min. 8 znaków.',
        validationType: 'string'
      },
      {
        type: 'button',
        value: 'Zaloguj'
      }
    ]
  }
  const style = {
    root: {
      background: 'white',
      padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '25px',
      fontWeight: 700,
      maxWidth: BreakpointsHelper.isMobile() ? 'none' : '320px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center'
    },
    desc: {
      maxWidth: '320px',
      fontSize: BreakpointsHelper.isMobile() ? '16px' : '18px',
      fontWeight: BreakpointsHelper.isMobile() ? '400' : '300',
      textTransform: 'none',
      textAlign: 'center'
    },
    icon: {
      marginRight: '5px'
    }
  }
  const createData = async (res) => {
    const data = {
      accessToken: res?.res?.accessToken,
      uid: res?.res?.uid,
      email: res?.res?.email,
      photoURL: res?.res?.photoURL,
      role: res?.database?.role || 'user',
      phone: res?.database?.phone,
      name: res?.database?.name,
      apartment: res?.database?.apartment,
      street: res?.database?.street,
      house: res?.database?.house,
      defaultLang: res?.database?.defaultLang
    }
    LocalStorageHelper.set('user', data)
    return data
  }
  const handleLogin = (e) => {
    UserHelper.login(e).then(res => {
      if (res) {
        if (res?.res?.emailVerified) {
          createData(res).then(res => {
            if (res) {
              if (res?.role === 'admin') {
                props.location.history.push('/dashboard')
              }
            }
          })
        }
        if (!res?.res?.emailVerified) enqueueSnackbar('Dane do logowanie są niepoprawne, bądź konto nie jest aktywowane z emaila za pomocą linku.', {variant: 'warning'})
      } else enqueueSnackbar('Wystąpił problem z logowaniem.', {variant: 'error'})
    }).catch((e) => {
      enqueueSnackbar('Dane do logowanie są niepoprawne.', {variant: 'error'})
    })
  }
  useEffect(() => {
    if (LocalStorageHelper.get('user')) {
      const savePassword = CookiesHelper.get('savePassword')
      const saveEmail = CookiesHelper.get('saveEmail')
      if (saveEmail && savePassword) {
        props.location.history.push('/dashboard')
      }
    }
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {seo.title}
        </title>
        <link
          rel="icon"
          href={favicon}
        />
      </Helmet>
      <div style={style.root}>
        Logowanie
        <div style={style.desc}>
          Zaloguj się za pomocą emaila oraz hasła.
        </div>
        <FormGenerator data={formData} submit={(e) => handleLogin(e)} {...props}/>
      </div>
    </>
  )
}

export default LoginPage
