export default {
  getAllItems: () => {
    return [
      'Aplikacja webowa',
      'Strona internetowa',
      'Projekt logo',
      'Projekt wizytówki',
      'Projekt ulotki',
      'Projekt plakatu',
      'Projekt baneru',
      'Analiza fraz kluczowych',
      'Optymalizacja strony pod kątem SEO',
      'Projekt pieczątki'
    ].map(label => ({value: label, label}))
  }
}
