import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import './CompanyPage.scss'
import {Grid, useTheme} from '@mui/material'
import favicon from '../../assets/configFiles/logo.png'
import {Helmet} from 'react-helmet'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import {getAllDataFromPath, updateOrCreateDocument} from '../../firebase'
import {useSnackbar} from 'notistack'
import {useHistory} from 'react-router'
import {useEffect, useState} from 'react'
import SkeletonLoader from '../../components/atoms/SkeletionLoader/SkeletonLoader'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'

const CompanyPage = () => {
  const history = useHistory()
  const pathName = history.location.pathname.search('editCompany') >= 0
  const id = history.location.pathname.split('/')[2]
  const {enqueueSnackbar} = useSnackbar()
  const theme = useTheme()
  const seo = theme.config.seo
  const [data, setData] = useState(null)
  const [formData, setFormData] = useState({ elements: []})
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    arrayNotExist: {
      fontSize: '16px',
      marginTop: '20px',
      color: '#2C296A',
      background: '#ededed',
      padding: '20px',
      width: 'auto',
      marginLeft: '20px',
      borderRadius: '50px'
    },
    title: {
      fontSize: '22px',
      fontWeight: '600',
      marginBottom: '20px',
      color: 'black !important'
    },
    description: {
      fontSize: '16px',
      fontWeight: '400',
      marginBottom: '50px',
      whiteSpace: 'pre-wrap',
      color: 'black !important'
    },
    text: {
      color: 'black !important'
    },
    img: {
      width: '100px',
      height: '100px',
      fitObject: 'cover',
      borderRadius: '50%'
    },
    footer: {
      marginTop: '-15px'
    }
  }
  const handleSubmit = (e) => {
    updateOrCreateDocument('companies', id || null, e).then((res) => {
      if (res) {
        enqueueSnackbar('Dodano firmę poprawnie.', {variant: 'success'})
        setTimeout(() => {
          history.push('/companies')
        }, 1000)
      }
    })
  }
  useEffect(() => {
    if (pathName) {
      getAllDataFromPath('companies').then((res) => {
        if (res) {
          const temp = []
          res?.forEach(el => {
            if (el?.id === id) {
              temp.push(el)
            }
          })
          setData(temp[0]?.data)
        }
      })
    }
  }, [pathName])
  useEffect(() => {
    setFormData({
      elements: [
        {
          name: 'companyName',
          label: 'Nazwa firmy / Nazwa marki',
          value: data?.companyName,
          type: 'text',
        },
        {
          name: 'nip',
          label: 'NIP',
          type: 'text',
          value: data?.nip,
          required: false,
        },
        {
          name: 'firstName',
          label: 'Imię',
          value: data?.firstName,
          type: 'text',
        },
        {
          name: 'lastName',
          label: 'Nazwisko',
          value: data?.lastName,
          type: 'text',
        },
        {
          name: 'address',
          label: 'Adres',
          value: data?.address,
          type: 'text',
        },
        {
          name: 'email',
          label: 'Email',
          value: data?.email,
          type: 'text',
        },
        {
          name: 'phone',
          label: 'Telefon',
          type: 'text',
          value: data?.phone,
          required: false,
        },
        {
          name: 'contactType',
          label: 'Preferowana forma kontaktu',
          items: [
            { value: 'FB', name: 'FB' },
            { value: 'Tel', name: 'Tel' },
            { value: 'Email', name: 'Email' },
          ],
          multiple: true,
          defaultValue: data?.contactType,
          type: 'multiselect',
        },
        {
          name: 'bankAccount',
          label: 'Numer konta bankowego',
          type: 'text',
          value: data?.bankAccount,
          required: false,
        },
        {
          name: 'website',
          label: 'Adres strony internetowej',
          type: 'text',
          value: data?.website,
          required: false,
        },
        {
          name: 'linkFacebook',
          label: 'Link do Facebook',
          type: 'text',
          value: data?.linkFacebook,
          required: false,
        },
        {
          name: 'linkInstagram',
          label: 'Link do Instagram',
          type: 'text',
          value: data?.linkInstagram,
          required: false,
        },
        {
          name: 'linkYoutube',
          label: 'Link do Youtube',
          type: 'text',
          value: data?.linkYoutube,
          required: false,
        },
        {
          name: 'linkLinkedIn',
          label: 'Link do LinkedIn',
          type: 'text',
          value: data?.linkLinkedIn,
          required: false,
        },
        {
          name: 'googleDrive',
          label: 'Link do Google Drive',
          type: 'text',
          value: data?.googleDrive,
          required: false,
        },
        {
          name: 'services',
          label: 'Usługi wykonane',
          options: [
            { value: 'Brak', label: 'Brak' },
            { value: 'Aplikacja webowa', label: 'Aplikacja webowa' },
            { value: 'Strona internetowa', label: 'Strona internetowa' },
            { value: 'Logo', label: 'Logo' },
            { value: 'Wizytówka', label: 'Wizytówka' },
            { value: 'Ulotka', label: 'Ulotka' },
            { value: 'Papier firmowy', label: 'Papier firmowy' },
            { value: 'Reklamy Google Ads', label: 'Reklamy Google Ads' },
            { value: 'Render', label: 'Render' },
            { value: 'Prace konfiguracyjne', label: 'Prace konfiguracyjne' },
          ],
          multiple: true,
          value: data?.services,
          type: 'autocomplete',
        },
        {
          name: 'tags',
          label: 'Tagi o kliencie',
          options: [
            { value: 'Brak', label: 'Brak' },
            { value: 'Wycena', label: 'Wycena' },
            { value: 'Przed zleceniem', label: 'Przed zleceniem' },
            { value: 'Jedno zlecenie', label: 'Jedno zlecenie' },
            { value: 'Dwa zlecenia', label: 'Dwa zlecenia' },
            { value: 'Trzy i więcej zleceń (stały klient)', label: 'Trzy i więcej zleceń (stały klient)' },
            { value: 'Problem z współpracą', label: 'Problem z współpracą' },
          ],
          multiple: true,
          value: data?.tags,
          type: 'autocomplete',
        },
        {
          name: 'typ',
          label: 'Typ współpracy',
          options: [
            { value: 'B2B / B2B', label: 'B2B / B2B' },
            { value: 'B2B / Osoba fizyczna', label: 'B2B / Osoba fizyczna' },
            { value: 'Praca na etat', label: 'Praca na etat' },
            { value: 'Barter / Barter', label: 'Barter / Barter' },
          ],
          value: data?.typ,
          multiple: true,
          type: 'autocomplete',
        },
        {
          name: 'files',
          type: 'files',
          label: 'Pliki',
          buttonName: 'Wgraj pliki',
          pathName: `/files/${LocalStorageHelper.get('user')?.accessToken}`,
          urlUpload: `/files/${LocalStorageHelper.get('user')?.accessToken}`,
          value: data?.files,
          validationType: 'string',
        },
        {
          type: 'button',
          value: id ? 'Zapisz firmę' : 'Stwórz firmę'
        },
      ]
    })
  }, [data])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {seo.title}
        </title>
        <link
          rel="icon"
          href={favicon}
        />
      </Helmet>
      <div style={style.root}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <SkeletonLoader variable={formData?.elements?.length > 0} time={1000} count={50}>
              <FormGenerator data={formData} submit={(e) => handleSubmit(e)}/>
            </SkeletonLoader>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default CompanyPage
